<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">


          <a-row :gutter="48">
            <a-col :md="4" :sm="24">
              <a-form-item label="手机号">
                <a-input v-model="queryParams.mobile" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="发送状态">
                <a-select placeholder="请选择" v-model="queryParams.sendStatus" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in dict.type['extra_sms_send_status']" :key="index"
                                   :value="d.value"
                  >{{ d.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template>
              <a-col :md="4" :sm="24">
                <a-form-item label="接收状态">
                  <a-select placeholder="请选择" v-model="queryParams.receiveStatus" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in dict.type['extra_sms_receive_status']" :key="index"
                                     :value="d.value"
                    >{{ d.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} "
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search"/>查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo"/>重置</a-button>

              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operations">
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"
        />
      </div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
      >
        <span slot="templateType" slot-scope="text, record">
          <dict-tag :options="dict.type['extra_sms_template_type']" :value="record.templateType"/>
        </span>
        <span slot="sendStatus" slot-scope="text, record">
           <dict-tag :options="dict.type['extra_sms_send_status']" :value="record.sendStatus"/>
        </span>
        <span slot="sendTime" slot-scope="text, record">
           <span>{{ parseTime(record.sendTime, '{y}-{m}-{d}') }}</span>
        </span>
        <span slot="receiveStatus" slot-scope="text, record">
          <dict-tag :options="dict.type['extra_sms_receive_status']" :value="record.receiveStatus"/>
        </span>
        <span slot="receiveTime" slot-scope="text, record">
          <span>{{ parseTime(record.receiveTime, '{y}-{m}-{d}') }}</span>
        </span>

        <span slot="channelCode" slot-scope="text, record">
           <div>{{ formatChannelSignature(record.channelId) }}</div>
          <dict-tag :options="dict.type['extra_sms_channel']" :value="record.channelCode"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          <span>{{ parseTime(record.createTime, '{y}-{m}-{d}') }}</span>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParams.pageNum"
        :total="total"
        :page-size="queryParams.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listLog } from '@/api/sms/log'
import { getSimpleSmsChannels } from '@/api/sms/channel'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Log',
  mixins: [tableMixin],
  dicts: ['sys_normal_disable', 'extra_sms_channel', 'extra_sms_template_type', 'extra_sms_receive_status', 'extra_sms_send_status'],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        mobile: undefined,
        sendStatus: undefined,
        receiveStatus: undefined
      },
      columns: [
        {
          title: '短信类型',
          dataIndex: 'templateType',
          scopedSlots: { customRender: 'templateType' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '短信内容',
          dataIndex: 'templateContent',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '发送状态',
          dataIndex: 'sendStatus',
          scopedSlots: { customRender: 'sendStatus' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '发送时间',
          dataIndex: 'sendTime',
          scopedSlots: { customRender: 'sendTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '发送描述',
          dataIndex: 'apiSendMsg',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '接收状态',
          dataIndex: 'receiveStatus',
          scopedSlots: { customRender: 'receiveStatus' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '接收时间',
          dataIndex: 'receiveTime',
          scopedSlots: { customRender: 'receiveTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '短信渠道',
          dataIndex: 'channelCode',
          scopedSlots: { customRender: 'channelCode' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    // 获得短信渠道
    getSimpleSmsChannels().then(response => {
      this.channelOptions = response.data
    })
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询短信日志列表 */
    getList() {
      this.loading = true
      listLog(this.queryParams).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        channelId: null,
        channelCode: null,
        templateId: null,
        templateCode: null,
        templateType: null,
        templateContent: null,
        templateParams: null,
        apiTemplateId: null,
        mobile: null,
        userId: null,
        userType: null,
        sendStatus: 0,
        sendTime: null,
        sendCode: null,
        sendMsg: null,
        apiSendCode: null,
        apiSendMsg: null,
        apiRequestId: null,
        apiSerialNo: null,
        receiveStatus: 0,
        receiveTime: null,
        apiReceiveCode: null,
        apiReceiveMsg: null,
        creator: null,
        createTime: null,
        modifier: null,
        updateTime: null,
        isDeleted: null
      }
      this.resetForm('form')
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        mobile: undefined,
        sendStatus: undefined,
        receiveStatus: undefined
      },
        this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      this.open = true
      this.title = '添加短信日志'
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset()
      const id = row.id || this.ids
      getLog(id).then(response => {
        this.form = response.data
        this.open = true
        this.title = '修改短信日志'
      })
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            updateLog(this.form).then(response => {
              this.$modal.msgSuccess('修改成功')
              this.open = false
              this.getList()
            })
          } else {
            addLog(this.form).then(response => {
              this.$modal.msgSuccess('新增成功')
              this.open = false
              this.getList()
            })
          }
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids
      this.$modal.confirm('是否确认删除短信日志编号为"' + ids + '"的数据项？').then(function() {
        return delLog(ids)
      }).then(() => {
        this.getList()
        this.$modal.msgSuccess('删除成功')
      }).catch(() => {
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download('extra/log/export', {
        ...this.queryParams
      }, `log_${new Date().getTime()}.xlsx`)
    },
    /** 格式化短信渠道 */
    formatChannelSignature(channelId) {
      for (const channel of this.channelOptions) {
        if (channel.id === channelId) {
          return channel.signature
        }
      }
      return '找不到签名：' + channelId
    },
    onShowSizeChange(current, pageSize) {
      this.queryParams.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParams.pageNum = current
      this.queryParams.pageSize = pageSize
      this.getList()
    }, toggleAdvanced() {
      this.advanced = !this.advanced
    }
  }
}
</script>
